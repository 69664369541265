<template>
  <div
    class="rounded-3xl overflow-hidden relative bg-center bg-cover text-center px-8 pt-5 pb-8 text-white flex flex-col shadow-xl group"
    :style="{
      backgroundImage: 'url(' + backgroundImage + ')',
      'min-height': '20rem',
    }"
  >
    <div class="absolute top-0 left-0 right-0 bottom-0 px-7 pt-7 pb-7 text-white flex flex-col" style="z-index: 1">
      <div class="flex flex-1 items-start text-left">
        <div class="flex flex-col justify-start flex-1">
          <div class="text-lg font-bold text-left">
            <span class="juc-text-color">JUC&nbsp;</span>
            <span class="text-white">{{ edition.name }}</span>
          </div>
          <div
            v-if="title != null && title.length > 0"
            class="font-extrabold uppercase"
            :class="{
              'text-xl': sportName == null,
              'text-base': sportName != null,
            }"
          >
            <v-clamp :max-lines="3"> {{ title }}</v-clamp>
          </div>
        </div>

        <div
          v-if="tournament.status == 'signup-open'"
          class="flex-shrink-0 bg-primary relative text-white gap-2 font-semibold rounded-full px-4 py-1"
        >
          Inscrições abertas
        </div>

        <div v-else-if="tournament.status == 'signup-closed'">
          <div class="text-sm opacity-70 font-medium">Inscrições encerradas</div>
        </div>
        <div v-else-if="tournament.status == 'inactive'">
          <div class="text-primary font-semibold">Brevemente</div>
        </div>
      </div>
      <div class="flex items-center gap-5">
        <div class="flex-1 flex flex-col justify-center text-left gap-1">
          <div class="text-sm font-bold">Torneio</div>
          <div v-if="title != null && title.length > 0" class="font-extrabold text-2xl mb-3">
            <v-clamp :max-lines="3"> {{ tournament.title[language] }}</v-clamp>
          </div>
          <div v-else class="text-opacity-50 mb-3">Sem título</div>
          <div class="flex gap-7">
            <div>
              <div class="text-sm">Tipo</div>
              <div class="font-semibold">
                <v-clamp :max-lines="1">
                  {{ $t(`sportType.${sport.type}`) }}
                </v-clamp>
              </div>
            </div>
            <div>
              <div class="text-sm">Género</div>
              <div class="font-semibold">
                <v-clamp :max-lines="1">
                  {{ tournament.gender == 'M' ? 'Masculino' : tournament.gender == 'F' ? 'Feminino' : 'Misto' }}
                </v-clamp>
              </div>
            </div>
            <div v-if="sportName != null">
              <div class="text-sm">Modalidade</div>
              <v-clamp class="font-semibold" :max-lines="1">
                {{ sportName }}
              </v-clamp>
            </div>
          </div>
        </div>
        <div class="text-center flex flex-col items-center gap-0">
          <div class="text-lg font-extrabold -mb-3">
            {{ numTeams }}
          </div>
          <ApprovedTeamsIcon class="h-20 w-20" />
          <div class="text-xs font-semibold h-10 -mt-2">Equipas<br />Aprovadas</div>
        </div>
        <div class="text-center flex flex-col items-center gap-0">
          <div class="text-lg font-extrabold -mb-3">
            {{ numPlayers }}
          </div>
          <ParticipantsIcon class="h-20 w-20" />
          <div class="text-xs font-semibold h-10 -mt-2">Participantes</div>
        </div>
      </div>
    </div>
    <div
      class="bg-black h-full w-full top-0 left-0 absolute bg-opacity-40 group-hover:bg-opacity-50 as-cover"
      style="z-index: 0"
      :class="{
        'as-cover-black': overlayColor == 'black',
        'as-cover-green': overlayColor == 'green',
        'as-cover-blue': overlayColor == 'blue',
        'as-cover-orange': overlayColor == 'orange',
        'as-cover-green-dark': overlayColor == 'green-dark',
      }"
    ></div>
  </div>
</template>

<script>
import { LEAGUES_PER_TYPE } from '@/utils/index.js'
import ApprovedTeamsIcon from '@/components/icons/ApprovedTeamsIcon'
import ParticipantsIcon from '@/components/icons/ParticipantsIcon'
export default {
  name: 'BannerTournament',
  components: {
    ApprovedTeamsIcon,
    ParticipantsIcon,
  },
  props: {
    tournament: {
      type: Object,
      required: true,
    },
    league: {
      type: Object,
      required: true,
    },
    sport: {
      type: Object,
      required: true,
    },
    edition: {
      type: Object,
      required: true,
    },
    numPlayers: {
      type: Number,
      default: 0,
    },
    numTeams: {
      type: Number,
      default: 0,
    },
  },
  computed: {
    sportName() {
      return this.sport?.modality.title[this.language] || null
    },
    language() {
      return this.$store.state.language || 'pt'
    },
    overlayColor() {
      return LEAGUES_PER_TYPE[this.league.type].overlay
    },
    backgroundImage() {
      return `https://static.fw.uc.pt/banners/${LEAGUES_PER_TYPE[this.league.type].background}.jpg`
    },
    leagues() {
      return LEAGUES_PER_TYPE
    },
    user() {
      return this.$store.getters.getUser
    },
    subtitle() {
      return this.league?.target?.[this.language] || null
    },
    title() {
      return this.league?.title?.[this.language] || null
    },
    // Checks
    isStudent() {
      return this.call.validations.is_student
    },
  },
}
</script>

<style scoped>
.as-cover {
  background: rgb(50, 68, 87);
  background: linear-gradient(0deg, rgba(50, 68, 87, 0.95) 25%, rgba(50, 68, 87, 0.35) 85%);
}
.as-cover-black {
  background: rgba(0, 0, 0, 0.7);
}
.as-cover-green {
  background: rgba(132, 174, 23, 0.7);
}
.as-cover-blue {
  background: rgba(31, 169, 235, 0.7);
}
.as-cover-orange {
  background: rgba(247, 160, 38, 0.7);
}
.as-cover-green-dark {
  background: rgba(23, 85, 0, 0.7);
}
</style>

<i18n>
{
  "pt": {
    "sportType": {
      "colective": "Coletivo",
      "individual": "Individual"
    }
  },
  "en": {
    "sportType": {
      "colective": "Colective",
      "individual": "Individual"
    }
  }
}
</i18n>
